import { createRequestConfig } from '@src/service/createRequestConfig';

export const approveCashOutUsingPost = createRequestConfig<
  {
    requestBody: CashOutApproveOrRejectRequest;
  },
  CashOutApproveOrRejectResponse
>('approveCashOutUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/cash-out/approve`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const checkEmailExistsUsingPost = createRequestConfig<{
  requestBody: EmailRequest;
}>('checkEmailExistsUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/email/exists`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const closeAlpacaAccountUsingDelete = createRequestConfig<{
  alpacaId: string;
  customerId: number;
}>('closeAlpacaAccountUsingDelete', ({ customerId, alpacaId }) => ({
  url: `/admin-portal/v1/customers/account/${customerId}/alpaca/${alpacaId}`,
  method: 'DELETE',
}));

export const createStockAccountUsingPost = createRequestConfig<
  {
    id: number;
    requestBody: CreateStockAccountRequest;
  },
  CommonResponseObject
>('createStockAccountUsingPost', ({ id, requestBody }) => ({
  url: `/admin-portal/v1/customers/${id}/stock/account`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const downloadUserTransactionHistoriesUsingGet = createRequestConfig<{
  request: QueryUserTransactionHistoryListRequest;
}>('downloadUserTransactionHistoriesUsingGet', ({ request }) => ({
  url: `/admin-portal/v1/customers/transactions-history/export`,
  method: 'GET',
  params: {
    ...request,
  },
}));

export const findCustomerByNanoIdsUsingGet = createRequestConfig<
  {
    nanoIds: number[];
  },
  PersonalDetail[]
>('findCustomerByNanoIdsUsingGet', ({ nanoIds }) => ({
  url: `/admin-portal/v1/customers`,
  method: 'GET',
  params: {
    nanoIds,
  },
}));

export const findCustomerByPhoneNumberUsingGet = createRequestConfig<
  {
    phoneNumber: string;
  },
  PersonalDetail
>('findCustomerByPhoneNumberUsingGet', ({ phoneNumber }) => ({
  url: `/admin-portal/v1/customers/phone-number/${phoneNumber}`,
  method: 'GET',
}));

export const getAccountDetailUsingGet = createRequestConfig<
  {
    customerId: number;
  },
  AccountDetailDto
>('getAccountDetailUsingGet', ({ customerId }) => ({
  url: `/admin-portal/v1/customers/${customerId}`,
  method: 'GET',
}));

export const getCustomerLiquidationHistoryUsingGet = createRequestConfig<
  {
    customerId?: string;
    page?: number;
    size?: number;
  },
  PaginationResponseAccountLiquidationResponse
>('getCustomerLiquidationHistoryUsingGet', ({ customerId, page, size }) => ({
  url: `/admin-portal/v1/customers/liquidation/history`,
  method: 'GET',
  params: {
    customerId,
    page,
    size,
  },
}));

export const getCustomerLiquidationStatusUsingGet = createRequestConfig<
  {
    customerId?: string;
    page?: number;
    size?: number;
  },
  PaginationResponseAccountLiquidationResponse
>('getCustomerLiquidationStatusUsingGet', ({ customerId, page, size }) => ({
  url: `/admin-portal/v1/customers/liquidation`,
  method: 'GET',
  params: {
    customerId,
    page,
    size,
  },
}));

export const getOneBrickReconStatusUsingGet = createRequestConfig<
  {
    orderId: string;
  },
  CommonResponseString
>('getOneBrickReconStatusUsingGet', ({ orderId }) => ({
  url: `/admin-portal/v1/customers/cash-out/one-brick/recon/status`,
  method: 'GET',
  params: {
    orderId,
  },
}));

export const getPendingCashOutRequestUsingPost = createRequestConfig<
  {
    page?: number;
    requestBody: CashOutPendingRequestFilter;
    size?: number;
  },
  PaginationResponseCashOutPendingRequestResponse
>('getPendingCashOutRequestUsingPost', ({ page, size, requestBody }) => ({
  url: `/admin-portal/v1/customers/pending/cashout-request`,
  method: 'POST',
  data: requestBody,
  params: {
    page,
    size,
  },
  headers: { 'Content-Type': 'application/json' },
}));

export const queryAllUserTransactionHistoriesUsingGet = createRequestConfig<
  {
    request: QueryUserTransactionHistoryListRequest;
  },
  PaginationResponseUserTransactionHistoryResponse
>('queryAllUserTransactionHistoriesUsingGet', ({ request }) => ({
  url: `/admin-portal/v1/customers/transactions-history/all`,
  method: 'GET',
  params: {
    ...request,
  },
}));

export const queryNanoVestUserListUsingPost = createRequestConfig<
  {
    requestBody: QueryUserInfoListRequest;
  },
  PaginationResponseNanoVestUserDto
>('queryNanoVestUserListUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/query-userinfo-by-filter`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const queryTransactionDetailUsingGet = createRequestConfig<
  {
    orderId: string;
    walletId: number;
  },
  TransactionDetailResponse
>('queryTransactionDetailUsingGet', ({ orderId, walletId }) => ({
  url: `/admin-portal/v1/customers/transaction/${walletId}/${orderId}/detail`,
  method: 'GET',
}));

export const queryUserTransactionHistories1UsingGet = createRequestConfig<
  {
    request: QueryUserTransactionHistoryListRequest;
  },
  PaginationResponseUserTransactionHistoryResponse
>('queryUserTransactionHistories1UsingGet', ({ request }) => ({
  url: `/admin-portal/v1/customers/transactions-history`,
  method: 'GET',
  params: {
    ...request,
  },
}));

export const registerLiquidationRequestUsingPost = createRequestConfig<{
  requestBody: CustomerLiquidationRequest[];
}>('registerLiquidationRequestUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/liquidation`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const rejectCashOutUsingPost = createRequestConfig<
  {
    requestBody: CashOutApproveOrRejectRequest;
  },
  CashOutApproveOrRejectResponse
>('rejectCashOutUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/cash-out/reject`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const reopenClosedAlpacaAccountUsingGet = createRequestConfig<{
  alpacaId: string;
}>('reopenClosedAlpacaAccountUsingGet', ({ alpacaId }) => ({
  url: `/admin-portal/v1/customers/account/alpaca/${alpacaId}/re-open`,
  method: 'GET',
}));

export const resetPinCodeUsingPost = createRequestConfig<{
  customerId: number;
}>('resetPinCodeUsingPost', ({ customerId }) => ({
  url: `/admin-portal/v1/customers/${customerId}/reset-pin-code`,
  method: 'POST',
}));

export const updateAndVerifyEmailUsingPut = createRequestConfig<{
  id: number;
  requestBody: UpdateEmailRequest;
}>('updateAndVerifyEmailUsingPut', ({ id, requestBody }) => ({
  url: `/admin-portal/v1/customers/${id}/email`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateBatchNikUsingPost = createRequestConfig(
  'updateBatchNikUsingPost',
  () => ({
    url: `/admin-portal/v1/customers/update/batch/nik`,
    method: 'POST',
  }),
);

export const updateCustomerStatusUsingPost = createRequestConfig<{
  requestBody: UpdateCustomerStatusRequest;
}>('updateCustomerStatusUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/status`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateFinancialInfoUsingPut = createRequestConfig<{
  requestBody: UpdateUserFinancialInfoRequest;
}>('updateFinancialInfoUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/financial-info`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updatePinUsingPut = createRequestConfig<{
  requestBody: UpdatePinRequest;
}>('updatePinUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/pin/update`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateStockAccountUsingPatch = createRequestConfig<{
  id: number;
  requestBody: UpdateStockAccountRequest;
}>('updateStockAccountUsingPatch', ({ id, requestBody }) => ({
  url: `/admin-portal/v1/customers/${id}/stock/account`,
  method: 'PATCH',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateUserNameUsingPut = createRequestConfig<{
  requestBody: UpdateUserNameRequest;
}>('updateUserNameUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/customers/update/userName`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateUserPhoneNumberUsingPatch = createRequestConfig<{
  id: number;
  requestBody: UpdateCustomerPhoneNumberRequest;
}>('updateUserPhoneNumberUsingPatch', ({ id, requestBody }) => ({
  url: `/admin-portal/v1/customers/${id}/phone-number`,
  method: 'PATCH',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface AccountDetailDto {
  accountAlpacaStatus?: string;
  accountCreationDate?: number;
  accountFundSource?: string;
  accountNanoStatus?: string;
  alpacaId?: string;
  annualIncome?: string;
  dob?: number;
  emailId?: string;
  emailIdStatus?: string;
  fullName?: string;
  investibleAssets?: string;
  ktpId?: string;
  kycCompletionDate?: number;
  kycResult?: keyof typeof AccountDetailDtoKycResult;
  linkedCashoutAccountNum?: string;
  linkedCashoutAccountType?: string;
  linkedDebitCardNum?: string;
  linkedDebitCardType?: string;
  mutualFundVA?: string;
  nanoId?: number;
  nanoTag?: string;
  phoneNumber?: string;
  pob?: string;
  referralCode?: string;
  referralCodeDisabled?: boolean;
  trustedUser?: TrustedUserResponse;
  unverifiedEmail?: string;
}

export enum AccountDetailDtoKycResult {
  'NOT_STARTED' = 'NOT_STARTED',
  'SUCCESS' = 'SUCCESS',
  'ON_PROGRESS' = 'ON_PROGRESS',
  'FAIL' = 'FAIL',
  'PENDING' = 'PENDING',
}

export interface AccountLiquidationResponse {
  cryptoLiquidationStatus?: keyof typeof AccountLiquidationResponseCryptoLiquidationStatus;
  customerId?: number;
  fullName?: string;
  liquidateCrypto?: boolean;
  liquidateStock?: boolean;
  liquidateWallet?: boolean;
  liquidationId?: string;
  liquidationTime?: number;
  lockedOn?: number;
  nanoTag?: string;
  overallStatus?: keyof typeof AccountLiquidationResponseOverallStatus;
  remainingCryptoLiquidationAmount?: number;
  remainingStockLiquidationAmount?: number;
  remainingWalletLiquidationAmount?: number;
  requesterId?: string;
  stocksLiquidationStatus?: keyof typeof AccountLiquidationResponseStocksLiquidationStatus;
  totalCryptoLiquidationAmount?: number;
  totalLiquidationAmount?: number;
  totalStockLiquidationAmount?: number;
  totalWalletLiquidationAmount?: number;
  walletLiquidationStatus?: keyof typeof AccountLiquidationResponseWalletLiquidationStatus;
}

export enum AccountLiquidationResponseCryptoLiquidationStatus {
  'VALIDATION_SUCCESS' = 'VALIDATION_SUCCESS',
  'VALIDATION_ERROR' = 'VALIDATION_ERROR',
  'PENDING_IN_WALLET' = 'PENDING_IN_WALLET',
  'WAITING_FOR_APPROVAL' = 'WAITING_FOR_APPROVAL',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'PARTIAL_SUCCESS' = 'PARTIAL_SUCCESS',
  'CANCELLED' = 'CANCELLED',
  'SUCCESS_CLIENT_SIDE' = 'SUCCESS_CLIENT_SIDE',
}

export enum AccountLiquidationResponseOverallStatus {
  'VALIDATION_SUCCESS' = 'VALIDATION_SUCCESS',
  'VALIDATION_ERROR' = 'VALIDATION_ERROR',
  'PENDING_IN_WALLET' = 'PENDING_IN_WALLET',
  'WAITING_FOR_APPROVAL' = 'WAITING_FOR_APPROVAL',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'PARTIAL_SUCCESS' = 'PARTIAL_SUCCESS',
  'CANCELLED' = 'CANCELLED',
}

export enum AccountLiquidationResponseStocksLiquidationStatus {
  'VALIDATION_SUCCESS' = 'VALIDATION_SUCCESS',
  'VALIDATION_ERROR' = 'VALIDATION_ERROR',
  'PENDING_IN_WALLET' = 'PENDING_IN_WALLET',
  'WAITING_FOR_APPROVAL' = 'WAITING_FOR_APPROVAL',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'PARTIAL_SUCCESS' = 'PARTIAL_SUCCESS',
  'CANCELLED' = 'CANCELLED',
  'SUCCESS_CLIENT_SIDE' = 'SUCCESS_CLIENT_SIDE',
}

export enum AccountLiquidationResponseWalletLiquidationStatus {
  'VALIDATION_SUCCESS' = 'VALIDATION_SUCCESS',
  'VALIDATION_ERROR' = 'VALIDATION_ERROR',
  'PENDING_IN_WALLET' = 'PENDING_IN_WALLET',
  'WAITING_FOR_APPROVAL' = 'WAITING_FOR_APPROVAL',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'PARTIAL_SUCCESS' = 'PARTIAL_SUCCESS',
  'CANCELLED' = 'CANCELLED',
  'SUCCESS_CLIENT_SIDE' = 'SUCCESS_CLIENT_SIDE',
}

export interface BankAccountDataRequest {
  accountCode: string;
  accountNumber: string;
  walletId?: number;
}

export interface BankAccountResponse {
  code?: string;
  id?: number;
  logo?: string;
  name?: string;
}

export interface CashOutApproveOrRejectRequest {
  cashoutType?: string;
  extTransactionIds: number[];
  reason: string;
  userName?: string;
}

export interface CashOutApproveOrRejectResponse {
  cashoutStatus?: CashOutApproveOrRejectStatus[];
}

export interface CashOutApproveOrRejectStatus {
  extTransId?: number;
  message?: string;
  status?: string;
}

export interface CashOutPendingRequestFilter {
  createdAfter?: string;
  createdBefore?: string;
  filterByField?: string;
  filterValue?: string;
}

export interface CashOutPendingRequestResponse {
  amount?: number;
  cashOutProviderName?: string;
  createdAt?: string;
  extensionTxnId?: string;
  fee?: number;
  id?: number;
  orderId?: string;
  partnerStatus?: string;
  reason?: string;
  referenceId?: string;
  status?: string;
  subWalletType?: string;
  totalAmount?: number;
  updatedAt?: string;
  userName?: string;
  userSegment?: string;
  walletId?: number;
}

export interface CommonResponseObject {
  code?: number;
  data?: { [key: string]: any };
  message?: string;
}

export interface CommonResponseString {
  code?: number;
  data?: string;
  message?: string;
}

export interface CreateStockAccountRequest {
  approveReason?: string;
}

export interface CustomerLiquidationRequest {
  customerId?: number;
  liquidateCrypto?: boolean;
  liquidateStock?: boolean;
  liquidateWallet?: boolean;
  liquidationStrategyCrypto?: keyof typeof CustomerLiquidationRequestLiquidationStrategyCrypto;
}

export enum CustomerLiquidationRequestLiquidationStrategyCrypto {
  'SELL_TO_EXCHANGE' = 'SELL_TO_EXCHANGE',
  'HOLD_LIQUIDATED_ASSET' = 'HOLD_LIQUIDATED_ASSET',
}

export interface EmailRequest {
  adminId?: number;
  email?: string;
}

export interface NanoVestUserDto {
  accountStatus?: string;
  bannedUntil?: number;
  createdAt?: number;
  customerId?: number;
  emailAddress?: string;
  fullName?: string;
  isNanovestTick?: boolean;
  isOfficialAccount?: boolean;
  kycStatus?: string;
  nanoId?: number;
  nanoTag?: string;
  phoneNumber?: string;
  socialDisplayName?: string;
  socialStatus?: string;
  updatedAt?: number;
}

export interface PaginationResponseAccountLiquidationResponse {
  items?: AccountLiquidationResponse[];
  totalElements?: number;
  totalPages?: number;
}

export interface PaginationResponseCashOutPendingRequestResponse {
  items?: CashOutPendingRequestResponse[];
  totalElements?: number;
  totalPages?: number;
}

export interface PaginationResponseNanoVestUserDto {
  items?: NanoVestUserDto[];
  totalElements?: number;
  totalPages?: number;
}

export interface PaginationResponseUserTransactionHistoryResponse {
  items?: UserTransactionHistoryResponse[];
  totalElements?: number;
  totalPages?: number;
}

export interface PersonalDetail {
  fullName?: string;
  nanoId?: number;
  nanoTag?: string;
  phoneNumber?: string;
}

export interface PhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

export interface QueryUserInfoListRequest {
  accountStatus?: string;
  currentPage: number;
  customerId?: string;
  emailAddress?: string;
  fullName?: string;
  isNanovestTick?: boolean;
  isOfficialAccount?: boolean;
  isSocialProfile?: boolean;
  mutualFundsVA?: string;
  nanoTag?: string;
  nik?: string;
  pageSize: number;
  phoneNumber?: string;
  socialStatus?: string;
  sort?: string;
}

export interface QueryUserTransactionHistoryListRequest {
  currentPage: number;
  date?: string;
  orderId?: string;
  pageSize: number;
  products: (keyof typeof QueryUserTransactionHistoryListRequestProducts)[];
  status?: keyof typeof QueryUserTransactionHistoryListRequestStatus;
  timezone?: number;
  userId: number;
}

export enum QueryUserTransactionHistoryListRequestProducts {
  'TWO_FA_RESET' = 'TWO_FA_RESET',
  'PIN_RESET' = 'PIN_RESET',
  'BUY_CRYPTO' = 'BUY_CRYPTO',
  'CRYPTO_MARKETPLACE' = 'CRYPTO_MARKETPLACE',
  'DELIST_CRYPTO' = 'DELIST_CRYPTO',
  'DEX_TOP_UP' = 'DEX_TOP_UP',
  'DEX_WITHDRAWAL' = 'DEX_WITHDRAWAL',
  'LIQUIDATE_CRYPTO' = 'LIQUIDATE_CRYPTO',
  'RECEIVE_CRYPTO' = 'RECEIVE_CRYPTO',
  'REFUND_CRYPTO' = 'REFUND_CRYPTO',
  'SELL_CRYPTO' = 'SELL_CRYPTO',
  'SELL_STOCK' = 'SELL_STOCK',
  'SEND_CRYPTO' = 'SEND_CRYPTO',
  'STAKE_CRYPTO' = 'STAKE_CRYPTO',
  'TRANSFER_CRYPTO' = 'TRANSFER_CRYPTO',
  'UNSTAKE_CRYPTO' = 'UNSTAKE_CRYPTO',
  'BUY_STOCK' = 'BUY_STOCK',
  'DIVIDEND' = 'DIVIDEND',
  'DIVIDEND_TAX' = 'DIVIDEND_TAX',
  'DIVIDEND_WO_TAX' = 'DIVIDEND_WO_TAX',
  'INTEREST' = 'INTEREST',
  'LIMIT_BUY_STOCK' = 'LIMIT_BUY_STOCK',
  'LIMIT_SELL_STOCK' = 'LIMIT_SELL_STOCK',
  'LIQUIDATE_STOCK' = 'LIQUIDATE_STOCK',
  'LIQUIDATION_MERGE_AND_ACQUISITION' = 'LIQUIDATION_MERGE_AND_ACQUISITION',
  'MERGE_AND_ACQUISITION' = 'MERGE_AND_ACQUISITION',
  'BUY_GOLD' = 'BUY_GOLD',
  'CASH_IN_LIEU' = 'CASH_IN_LIEU',
  'PRINT_GOLD' = 'PRINT_GOLD',
  'SELL_GOLD' = 'SELL_GOLD',
  'MINT' = 'MINT',
  'BUY_MUTUAL_FUNDS' = 'BUY_MUTUAL_FUNDS',
  'SELL_MUTUAL_FUNDS' = 'SELL_MUTUAL_FUNDS',
  'CORPORATE_FUND_INTEREST' = 'CORPORATE_FUND_INTEREST',
  'DEPOSIT_CORPORATE_FUND' = 'DEPOSIT_CORPORATE_FUND',
  'WITHDRAW_CORPORATE_FUND' = 'WITHDRAW_CORPORATE_FUND',
  'CRYPTO_WALLET_DEPOSIT' = 'CRYPTO_WALLET_DEPOSIT',
  'CRYPTO_WALLET_DEPOSIT_IDDR' = 'CRYPTO_WALLET_DEPOSIT_IDDR',
  'CRYPTO_WALLET_RECOVERY_FEE' = 'CRYPTO_WALLET_RECOVERY_FEE',
  'CRYPTO_WALLET_WITHDRAWAL' = 'CRYPTO_WALLET_WITHDRAWAL',
  'CRYPTO_WALLET_WITHDRAWAL_IDDR' = 'CRYPTO_WALLET_WITHDRAWAL_IDDR',
  'ADJUSTMENT_ADD' = 'ADJUSTMENT_ADD',
  'ADJUSTMENT_DEDUCT' = 'ADJUSTMENT_DEDUCT',
  'BALANCE_ADJUSTMENT' = 'BALANCE_ADJUSTMENT',
  'CASHBACK_RECEIVED' = 'CASHBACK_RECEIVED',
  'CASHBACK_USED' = 'CASHBACK_USED',
  'LIQUIDATE_WALLET' = 'LIQUIDATE_WALLET',
  'P2P_REQUEST' = 'P2P_REQUEST',
  'P2P_TRANSFER' = 'P2P_TRANSFER',
  'TOPUP' = 'TOPUP',
  'WALLET_INTEREST' = 'WALLET_INTEREST',
  'WITHDRAW' = 'WITHDRAW',
  'EXTERNAL_TOPUP_CRYPTO_CASH' = 'EXTERNAL_TOPUP_CRYPTO_CASH',
  'EXTERNAL_WITHDRAW_CRYPTO_CASH' = 'EXTERNAL_WITHDRAW_CRYPTO_CASH',
  'INTERNAL_TRANSFER_TOPUP_CRYPTO_CASH' = 'INTERNAL_TRANSFER_TOPUP_CRYPTO_CASH',
  'INTERNAL_TRANSFER_TOPUP_NANOVEST_CASH' = 'INTERNAL_TRANSFER_TOPUP_NANOVEST_CASH',
  'INTERNAL_TRANSFER_WITHDRAW_CRYPTO_CASH' = 'INTERNAL_TRANSFER_WITHDRAW_CRYPTO_CASH',
  'INTERNAL_TRANSFER_WITHDRAW_NANOVEST_CASH' = 'INTERNAL_TRANSFER_WITHDRAW_NANOVEST_CASH',
  'CRYPTO_RAFFLE_TICKET_PURCHASE' = 'CRYPTO_RAFFLE_TICKET_PURCHASE',
  'NBT_STAKE' = 'NBT_STAKE',
  'REWARDS_MISSION' = 'REWARDS_MISSION',
  'REWARDS_REFERRAL' = 'REWARDS_REFERRAL',
  'CARD_BILL_CRYPTO_LIQUIDATION' = 'CARD_BILL_CRYPTO_LIQUIDATION',
  'CARD_BILL_PAYMENT' = 'CARD_BILL_PAYMENT',
  'CARD_BILL_REFUND' = 'CARD_BILL_REFUND',
  'CARD_BILL_WALLET_LIQUIDATION' = 'CARD_BILL_WALLET_LIQUIDATION',
  'DEPOSIT_CREDIT_INTEREST' = 'DEPOSIT_CREDIT_INTEREST',
  'DEPOSIT_CREDIT_PRINCIPAL' = 'DEPOSIT_CREDIT_PRINCIPAL',
  'DEPOSIT_DEBIT_PRINCIPAL' = 'DEPOSIT_DEBIT_PRINCIPAL',
  'DEPOSIT_TERMINATE_PENALTY' = 'DEPOSIT_TERMINATE_PENALTY',
}

export enum QueryUserTransactionHistoryListRequestStatus {
  'SUCCESS' = 'SUCCESS',
  'SCHEDULED' = 'SCHEDULED',
  'FAILED' = 'FAILED',
  'CANCELED' = 'CANCELED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'REFUNDED' = 'REFUNDED',
  'CANCELLATION_FAILED' = 'CANCELLATION_FAILED',
  'PARTIALLY_FILLED' = 'PARTIALLY_FILLED',
  'EXPIRED' = 'EXPIRED',
  'QUEUED_FOR_WITHDRAWAL' = 'QUEUED_FOR_WITHDRAWAL',
  'CLAIMED' = 'CLAIMED',
  'UNCLAIMED' = 'UNCLAIMED',
  'Success' = 'Success',
  'Failed' = 'Failed',
  'PENDING' = 'PENDING',
  'WAITING_APPROVAL' = 'WAITING_APPROVAL',
  'KYT_VERIFICATION' = 'KYT_VERIFICATION',
  'KYT_VERIFICATION_ERROR' = 'KYT_VERIFICATION_ERROR',
  'FREEZE' = 'FREEZE',
  'REJECTED' = 'REJECTED',
}

export interface RecurringBuyScheduleDetailResponseDto {
  recurringBuyScheduleDetailId?: number;
  scheduleExecution?: ScheduleExecution;
}

export interface ScheduleExecution {
  type?: string;
  value?: string;
}

export interface TransactionDetailResponse {
  category?: keyof typeof TransactionDetailResponseCategory;
  claimDate?: string;
  createdDate?: string;
  date?: string;
  expiryDate?: string;
  lastStatusUpdate?: string;
  orderNumber?: string;
  recurringBuy?: RecurringBuyScheduleDetailResponseDto;
  statusResponse?: keyof typeof TransactionDetailResponseStatusResponse;
  txnType?: string; // this is an adition since it available in response but not generated by API codegen
  transactionType?: string; // this is an adition since it available in response but not generated by API codegen
}

export enum TransactionDetailResponseCategory {
  'TOPUP' = 'TOPUP',
  'WITHDRAW' = 'WITHDRAW',
  'P2P_SEND' = 'P2P_SEND',
  'P2P_RECEIVE' = 'P2P_RECEIVE',
  'P2P_REQUEST' = 'P2P_REQUEST',
  'STOCK_BUY' = 'STOCK_BUY',
  'STOCK_SELL' = 'STOCK_SELL',
  'CRYPTO_BUY' = 'CRYPTO_BUY',
  'CRYPTO_SELL' = 'CRYPTO_SELL',
  'TRANSFER_CRYPTO' = 'TRANSFER_CRYPTO',
  'RECEIVE_CRYPTO' = 'RECEIVE_CRYPTO',
  'DIVIDEND' = 'DIVIDEND',
  'DIVIDEND_TAX' = 'DIVIDEND_TAX',
  'DIVIDEND_WO_TAX' = 'DIVIDEND_WO_TAX',
  'REWARDS_REFERRAL' = 'REWARDS_REFERRAL',
  'REWARDS_MISSION' = 'REWARDS_MISSION',
  'CASH_IN_LIEU' = 'CASH_IN_LIEU',
  'MERGE_AND_ACQUISITION' = 'MERGE_AND_ACQUISITION',
  'LIQUIDATION_MERGE_AND_ACQUISITION' = 'LIQUIDATION_MERGE_AND_ACQUISITION',
  'BALANCE_ADJUSTMENT' = 'BALANCE_ADJUSTMENT',
  'LIQUIDATE_CRYPTO' = 'LIQUIDATE_CRYPTO',
  'LIQUIDATE_STOCK' = 'LIQUIDATE_STOCK',
  'LIQUIDATE_WALLET' = 'LIQUIDATE_WALLET',
  'CORPORATE_FUND' = 'CORPORATE_FUND',
  'CASHBACK_USED' = 'CASHBACK_USED',
  'CASHBACK_RECEIVED' = 'CASHBACK_RECEIVED',
  'BUY_MUTUAL_FUNDS' = 'BUY_MUTUAL_FUNDS',
  'SELL_MUTUAL_FUNDS' = 'SELL_MUTUAL_FUNDS',
  'CRYPTO_WALLET_DEPOSIT' = 'CRYPTO_WALLET_DEPOSIT',
  'CRYPTO_WALLET_WITHDRAWAL' = 'CRYPTO_WALLET_WITHDRAWAL',
  'CRYPTO_WALLET_RECOVERY_FEE' = 'CRYPTO_WALLET_RECOVERY_FEE',
  'WALLET_INTEREST' = 'WALLET_INTEREST',
  'BUY_GOLD' = 'BUY_GOLD',
  'SELL_GOLD' = 'SELL_GOLD',
  'PRINT_GOLD' = 'PRINT_GOLD',
  'MINT' = 'MINT',
  'CARD_BILL_REFUND' = 'CARD_BILL_REFUND',
  'CARD_BILL_PAYMENT' = 'CARD_BILL_PAYMENT',
  'CARD_BILL_WALLET_LIQUIDATION' = 'CARD_BILL_WALLET_LIQUIDATION',
  'CARD_BILL_CRYPTO_LIQUIDATION' = 'CARD_BILL_CRYPTO_LIQUIDATION',
  'LIMIT_STOCK_BUY' = 'LIMIT_STOCK_BUY',
  'LIMIT_STOCK_SELL' = 'LIMIT_STOCK_SELL',
  'LOCKED_STAKING' = 'LOCKED_STAKING',
  'CRYPTO_MARKETPLACE' = 'CRYPTO_MARKETPLACE',
  'DEX_TOP_UP' = 'DEX_TOP_UP',
  'DEX_WITHDRAWAL' = 'DEX_WITHDRAWAL',
  'CRYPTO_CASH' = 'CRYPTO_CASH',
  'STAKE_CRYPTO' = 'STAKE_CRYPTO',
  'UNSTAKE_CRYPTO' = 'UNSTAKE_CRYPTO',
}

export enum TransactionDetailResponseStatusResponse {
  'SUCCESS' = 'SUCCESS',
  'SCHEDULED' = 'SCHEDULED',
  'FAILED' = 'FAILED',
  'CANCELED' = 'CANCELED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'REFUNDED' = 'REFUNDED',
  'CANCELLATION_FAILED' = 'CANCELLATION_FAILED',
  'PARTIALLY_FILLED' = 'PARTIALLY_FILLED',
  'EXPIRED' = 'EXPIRED',
  'QUEUED_FOR_WITHDRAWAL' = 'QUEUED_FOR_WITHDRAWAL',
  'CLAIMED' = 'CLAIMED',
  'UNCLAIMED' = 'UNCLAIMED',
  'Success' = 'Success',
  'Failed' = 'Failed',
  'PENDING' = 'PENDING',
  'WAITING_APPROVAL' = 'WAITING_APPROVAL',
  'KYT_VERIFICATION' = 'KYT_VERIFICATION',
  'KYT_VERIFICATION_ERROR' = 'KYT_VERIFICATION_ERROR',
  'FREEZE' = 'FREEZE',
  'REJECTED' = 'REJECTED',
}

export interface TrustedUserResponse {
  customerId?: number;
  email?: string;
  fullName?: string;
  phoneNumber?: string;
}

export interface UpdateCustomerPhoneNumberRequest {
  phoneNumber: PhoneNumber;
  reason: string;
}

export interface UpdateCustomerStatusRequest {
  accountLockedType?: keyof typeof UpdateCustomerStatusRequestAccountLockedType;
  customerStatus: keyof typeof UpdateCustomerStatusRequestCustomerStatus;
  id: number;
  reason: string;
}

export enum UpdateCustomerStatusRequestAccountLockedType {
  'PHONE_NUMBER_REASSIGN' = 'PHONE_NUMBER_REASSIGN',
  'OTHERS' = 'OTHERS',
}

export enum UpdateCustomerStatusRequestCustomerStatus {
  'ACTIVE' = 'ACTIVE',
  'TOKEN_LOCKED' = 'TOKEN_LOCKED',
  'ACCOUNT_LOCKED' = 'ACCOUNT_LOCKED',
  'DEACTIVATED' = 'DEACTIVATED',
  'PIN_LOCKED' = 'PIN_LOCKED',
}

export interface UpdateEmailRequest {
  email?: string;
  reason: string;
}

export interface UpdatePinRequest {
  customerId: number;
  newPinCode: string;
}

export interface UpdateStockAccountRequest {
  emailAddress?: string;
}

export interface UpdateUserFinancialInfoRequest {
  accountFundSource?: keyof typeof UpdateUserFinancialInfoRequestAccountFundSource;
  annualIncome?: keyof typeof UpdateUserFinancialInfoRequestAnnualIncome;
  customerId?: number;
  investibleAssets?: keyof typeof UpdateUserFinancialInfoRequestInvestibleAssets;
  reason?: string;
}

export enum UpdateUserFinancialInfoRequestAccountFundSource {
  'EMPLOYMENT_INCOME' = 'EMPLOYMENT_INCOME',
  'INVESTMENTS' = 'INVESTMENTS',
  'INHERITANCE' = 'INHERITANCE',
  'BUSINESS_INCOME' = 'BUSINESS_INCOME',
  'SAVINGS' = 'SAVINGS',
  'FAMILY' = 'FAMILY',
}

export enum UpdateUserFinancialInfoRequestAnnualIncome {
  'TIER_FIRST' = 'TIER_FIRST',
  'TIER_SECOND' = 'TIER_SECOND',
  'TIER_THIRD' = 'TIER_THIRD',
  'TIER_FOURTH' = 'TIER_FOURTH',
  'TIER_FIFTH' = 'TIER_FIFTH',
  'TIER_SIXTH' = 'TIER_SIXTH',
}

export enum UpdateUserFinancialInfoRequestInvestibleAssets {
  'TIER_FIRST' = 'TIER_FIRST',
  'TIER_SECOND' = 'TIER_SECOND',
  'TIER_THIRD' = 'TIER_THIRD',
  'TIER_FOURTH' = 'TIER_FOURTH',
  'TIER_FIFTH' = 'TIER_FIFTH',
}

export interface UpdateUserNameRequest {
  customerId?: number;
  userName?: string;
}

export interface UserTransactionHistoryResponse {
  amount?: number;
  amountDecimal?: number;
  createdAt?: string;
  item?: string;
  orderId?: string;
  orderType?: keyof typeof UserTransactionHistoryResponseOrderType;
  receiverId?: number;
  recurringBuy?: string;
  recurringBuyId?: number;
  senderId?: number;
  sourceId?: string;
  sourceType?: keyof typeof UserTransactionHistoryResponseSourceType;
  status?: keyof typeof UserTransactionHistoryResponseStatus;
  txnType?: keyof typeof UserTransactionHistoryResponseTxnType;
  unit?: number;
}

export enum UserTransactionHistoryResponseOrderType {
  'MARKET' = 'MARKET',
  'LIMIT' = 'LIMIT',
}

export enum UserTransactionHistoryResponseSourceType {
  'ALPACA' = 'ALPACA',
  'CRYPTO_CASH' = 'CRYPTO_CASH',
  'DEBIT_CARD' = 'DEBIT_CARD',
  'E_MONEY_DANA' = 'E_MONEY_DANA',
  'E_MONEY_GOPAY' = 'E_MONEY_GOPAY',
  'E_MONEY_LINKAJA' = 'E_MONEY_LINKAJA',
  'E_MONEY_OVO' = 'E_MONEY_OVO',
  'E_MONEY_SHOPEEPAY' = 'E_MONEY_SHOPEEPAY',
  'GIFT_CARD' = 'GIFT_CARD',
  'MERCHANT' = 'MERCHANT',
  'NANOVEST_CRYPTO_WALLET' = 'NANOVEST_CRYPTO_WALLET',
  'NANOVEST_WALLET' = 'NANOVEST_WALLET',
  'NANO_CARD' = 'NANO_CARD',
  'NANO_CARD_COLLATERAL' = 'NANO_CARD_COLLATERAL',
  'NANO_GOLD' = 'NANO_GOLD',
  'REWARDS' = 'REWARDS',
  'VIRTUAL_ACCOUNT' = 'VIRTUAL_ACCOUNT',
}

export enum UserTransactionHistoryResponseStatus {
  'SUCCESS' = 'SUCCESS',
  'SCHEDULED' = 'SCHEDULED',
  'FAILED' = 'FAILED',
  'CANCELED' = 'CANCELED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'REFUNDED' = 'REFUNDED',
  'CANCELLATION_FAILED' = 'CANCELLATION_FAILED',
  'PARTIALLY_FILLED' = 'PARTIALLY_FILLED',
  'EXPIRED' = 'EXPIRED',
  'QUEUED_FOR_WITHDRAWAL' = 'QUEUED_FOR_WITHDRAWAL',
  'CLAIMED' = 'CLAIMED',
  'UNCLAIMED' = 'UNCLAIMED',
  'Success' = 'Success',
  'Failed' = 'Failed',
  'PENDING' = 'PENDING',
  'WAITING_APPROVAL' = 'WAITING_APPROVAL',
  'KYT_VERIFICATION' = 'KYT_VERIFICATION',
  'KYT_VERIFICATION_ERROR' = 'KYT_VERIFICATION_ERROR',
  'FREEZE' = 'FREEZE',
  'REJECTED' = 'REJECTED',
}

export enum UserTransactionHistoryResponseTxnType {
  'TWO_FA_RESET' = 'TWO_FA_RESET',
  'PIN_RESET' = 'PIN_RESET',
  'BUY_CRYPTO' = 'BUY_CRYPTO',
  'CRYPTO_MARKETPLACE' = 'CRYPTO_MARKETPLACE',
  'DELIST_CRYPTO' = 'DELIST_CRYPTO',
  'DEX_TOP_UP' = 'DEX_TOP_UP',
  'DEX_WITHDRAWAL' = 'DEX_WITHDRAWAL',
  'LIQUIDATE_CRYPTO' = 'LIQUIDATE_CRYPTO',
  'RECEIVE_CRYPTO' = 'RECEIVE_CRYPTO',
  'REFUND_CRYPTO' = 'REFUND_CRYPTO',
  'SELL_CRYPTO' = 'SELL_CRYPTO',
  'SELL_STOCK' = 'SELL_STOCK',
  'SEND_CRYPTO' = 'SEND_CRYPTO',
  'STAKE_CRYPTO' = 'STAKE_CRYPTO',
  'TRANSFER_CRYPTO' = 'TRANSFER_CRYPTO',
  'UNSTAKE_CRYPTO' = 'UNSTAKE_CRYPTO',
  'BUY_STOCK' = 'BUY_STOCK',
  'DIVIDEND' = 'DIVIDEND',
  'DIVIDEND_TAX' = 'DIVIDEND_TAX',
  'DIVIDEND_WO_TAX' = 'DIVIDEND_WO_TAX',
  'INTEREST' = 'INTEREST',
  'LIMIT_BUY_STOCK' = 'LIMIT_BUY_STOCK',
  'LIMIT_SELL_STOCK' = 'LIMIT_SELL_STOCK',
  'LIQUIDATE_STOCK' = 'LIQUIDATE_STOCK',
  'LIQUIDATION_MERGE_AND_ACQUISITION' = 'LIQUIDATION_MERGE_AND_ACQUISITION',
  'MERGE_AND_ACQUISITION' = 'MERGE_AND_ACQUISITION',
  'BUY_GOLD' = 'BUY_GOLD',
  'CASH_IN_LIEU' = 'CASH_IN_LIEU',
  'PRINT_GOLD' = 'PRINT_GOLD',
  'SELL_GOLD' = 'SELL_GOLD',
  'MINT' = 'MINT',
  'BUY_MUTUAL_FUNDS' = 'BUY_MUTUAL_FUNDS',
  'SELL_MUTUAL_FUNDS' = 'SELL_MUTUAL_FUNDS',
  'CORPORATE_FUND_INTEREST' = 'CORPORATE_FUND_INTEREST',
  'DEPOSIT_CORPORATE_FUND' = 'DEPOSIT_CORPORATE_FUND',
  'WITHDRAW_CORPORATE_FUND' = 'WITHDRAW_CORPORATE_FUND',
  'CRYPTO_WALLET_DEPOSIT' = 'CRYPTO_WALLET_DEPOSIT',
  'CRYPTO_WALLET_DEPOSIT_IDDR' = 'CRYPTO_WALLET_DEPOSIT_IDDR',
  'CRYPTO_WALLET_RECOVERY_FEE' = 'CRYPTO_WALLET_RECOVERY_FEE',
  'CRYPTO_WALLET_WITHDRAWAL' = 'CRYPTO_WALLET_WITHDRAWAL',
  'CRYPTO_WALLET_WITHDRAWAL_IDDR' = 'CRYPTO_WALLET_WITHDRAWAL_IDDR',
  'ADJUSTMENT_ADD' = 'ADJUSTMENT_ADD',
  'ADJUSTMENT_DEDUCT' = 'ADJUSTMENT_DEDUCT',
  'BALANCE_ADJUSTMENT' = 'BALANCE_ADJUSTMENT',
  'CASHBACK_RECEIVED' = 'CASHBACK_RECEIVED',
  'CASHBACK_USED' = 'CASHBACK_USED',
  'LIQUIDATE_WALLET' = 'LIQUIDATE_WALLET',
  'P2P_REQUEST' = 'P2P_REQUEST',
  'P2P_TRANSFER' = 'P2P_TRANSFER',
  'TOPUP' = 'TOPUP',
  'WALLET_INTEREST' = 'WALLET_INTEREST',
  'WITHDRAW' = 'WITHDRAW',
  'EXTERNAL_TOPUP_CRYPTO_CASH' = 'EXTERNAL_TOPUP_CRYPTO_CASH',
  'EXTERNAL_WITHDRAW_CRYPTO_CASH' = 'EXTERNAL_WITHDRAW_CRYPTO_CASH',
  'INTERNAL_TRANSFER_TOPUP_CRYPTO_CASH' = 'INTERNAL_TRANSFER_TOPUP_CRYPTO_CASH',
  'INTERNAL_TRANSFER_TOPUP_NANOVEST_CASH' = 'INTERNAL_TRANSFER_TOPUP_NANOVEST_CASH',
  'INTERNAL_TRANSFER_WITHDRAW_CRYPTO_CASH' = 'INTERNAL_TRANSFER_WITHDRAW_CRYPTO_CASH',
  'INTERNAL_TRANSFER_WITHDRAW_NANOVEST_CASH' = 'INTERNAL_TRANSFER_WITHDRAW_NANOVEST_CASH',
  'CRYPTO_RAFFLE_TICKET_PURCHASE' = 'CRYPTO_RAFFLE_TICKET_PURCHASE',
  'NBT_STAKE' = 'NBT_STAKE',
  'REWARDS_MISSION' = 'REWARDS_MISSION',
  'REWARDS_REFERRAL' = 'REWARDS_REFERRAL',
  'CARD_BILL_CRYPTO_LIQUIDATION' = 'CARD_BILL_CRYPTO_LIQUIDATION',
  'CARD_BILL_PAYMENT' = 'CARD_BILL_PAYMENT',
  'CARD_BILL_REFUND' = 'CARD_BILL_REFUND',
  'CARD_BILL_WALLET_LIQUIDATION' = 'CARD_BILL_WALLET_LIQUIDATION',
  'DEPOSIT_CREDIT_INTEREST' = 'DEPOSIT_CREDIT_INTEREST',
  'DEPOSIT_CREDIT_PRINCIPAL' = 'DEPOSIT_CREDIT_PRINCIPAL',
  'DEPOSIT_DEBIT_PRINCIPAL' = 'DEPOSIT_DEBIT_PRINCIPAL',
  'DEPOSIT_TERMINATE_PENALTY' = 'DEPOSIT_TERMINATE_PENALTY',
}
