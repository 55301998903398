import { NUMBER_FORMATTING_REGEX } from '@src/constants/common';
import { Locales } from '@src/types/fund-management';

export const addThousandsSeparatorOnChange = (
  ele: number | string,
  locale = Locales.ID,
): string => {
  const finalAmount = Number(ele).toLocaleString(locale || Locales.ID);
  return finalAmount;
};

export const formatBalanceForNanoCuan = (
  balance: number | string,
  thousandSeparator = '.',
  decimalSeparator = ',',
): string => {
  if (balance === null || balance === undefined) return '0';
  const parsedAmount = parseFloat(String(balance));

  const numberBeforeDecimal = parsedAmount.toString().split('.')[0];
  const numberAfterDecimal = parsedAmount.toString().split('.')[1];
  const regexFormat = numberBeforeDecimal.replace(
    NUMBER_FORMATTING_REGEX,
    thousandSeparator,
  );
  return numberAfterDecimal
    ? regexFormat + decimalSeparator + numberAfterDecimal
    : regexFormat;
};

export const scientificToFixedNumber = (scientificNumber = 0): string => {
  if (!scientificNumber) {
    return '-';
  }

  if (Math.abs(scientificNumber) < 1.0) {
    const e = parseInt(scientificNumber.toString().split('e-')[1]);
    if (e) {
      return scientificNumber.toFixed(e).replace('.', ',');
    }
    return scientificNumber.toString().replace('.', ',');
  }

  return scientificNumber.toString().replace('.', ',');
};
